.spinner {
  text-align: center;
  background: rgba(0, 0, 0, 0);
  border-radius: 4px;
  height: 100%;
}

.breadcrumb-class {
  font-size: 18px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 0px;
  padding-left: 20px;
}

.content-class {
  margin: 12px 12px 0;
  overflow: initial;
  min-height: 100vh;
  padding: 12px;
}

.input-number-class {
  width: 100%;
  padding-right: 20px;
}

.input-number-class input {
  text-align: right !important;
}

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: darkred;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: red;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.login {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.login-card {
  width: 500px;
  padding-right: 20px;
  padding-left: 20px;
}

.form-input {
  color: black !important;
}

.form-label {
  font-weight: bold;
}

.menu-item {
  width: 24px;
  justify-content: space-between;
}

.ant-layout-sider-trigger {
  color: black !important;
  background: white !important;
}

.ant-layout-sider {
  background: white !important;
}

.avatar-class {
  background-color: #ffbf00;
  vertical-align: middle;
  justify-content: space-between;
  right: 4px;
}

.table-row-detail-single {
  width: 100% !important;
  text-align: justify;
}

.table-row-detail-duo {
  width: 50% !important;
  text-align: center;
}

.table-row-detail-trio {
  text-align: center;
}

.table-row-detail {
  width: 25% !important;
  text-align: center;
}

input[type="number"].no-spinner::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none !important;
  margin: 0 !important;
}

.ghost-button {
  color: black !important;
  border-width: 0 !important;
}

.mb {
  margin-bottom: 20px;
}

h2 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.custom-form-card .ant-card-body {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.custom-card .ant-card-body {
  padding-left: 10px !important;
  padding-right: 0px !important;
  padding-top: 10px !important;
  padding-bottom: 0px !important;
}
