th {
  height: 5px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

tr {
  height: 50px !important;
}

td {
  width: 80px !important;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.draggable-class {
  z-index: 1;
  top: 10px;
  left: 60px;
  height: 30px;
  display: flex;
  cursor: pointer !important;
  align-items: center;
  position: absolute;
  border-left-width: 6px;
  border-radius: 10px;
}

.today-class {
  background-color: lightcyan !important;
  border-left-width: thin;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: gainsboro;

  text-transform: capitalize;
  text-align: center !important;
}

.cell-class {
  border-left-width: thin;
  border-right-width: 0;
  border-top-width: 0;
  border-bottom-width: 0;
  border-style: solid;
  border-color: gainsboro;
  text-transform: capitalize;
  text-align: center !important;
  background-color: transparent !important;
}

.ant-table-measure-row {
  display: none;
}

.row-class .ant-table-cell-row-hover {
  background-color: none;
}

td:has(.droppable) {
  padding: 0px !important;
}

th::first-letter {
  text-transform: capitalize;
}

.info-modal {
  display: flex;
  justify-content: space-between;
}

.info-modal-title {
  margin: 0px;
  height: 50px;
}

.info-modal-actions {
  padding-right: 20px;
  bottom: 5px;
  position: relative;
}

.clickable-cell {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

.tag-cell {
  width: 100%;
  position: relative;
  background-color: lightGreen;
}

.payment-status-badge {
  font-size: 20px;
}

.calendar-date-header {
  font-size: 12px;
  text-transform: uppercase;
}

.calendar-day-header {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.calendar-day-class {
  color: #70757a;
  font-size: 14px;
}
